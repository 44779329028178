.login-container {
    margin: 0 auto;
    width: 50%;

    @media (max-width:600px) {
        width: 95%
    }
}

.react-tel-input .form-control{
    width: 100% !important;
}
