.brand-logo {
  left: 35% !important;
}

@media (max-width: 480px) {
  .brand-logo img {
    height: auto !important;
  }
}
.side-icons {
  display: flex;
  justify-self: flex-start;
  align-items: center;
}
