.order-summary-component {

    padding-top: 20px;


    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid gainsboro;
        border-top: 1px solid gainsboro;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .order-item-card {
        width: 100%;
        display: flex;
        align-items: flex-start;

        .img-section {
            width: 90px;
            margin-right: 20px;
            height: 90px;
        }
    }

    span {
        display: inline-block;
        color: #2d3436;
        margin-right:10px;
    }

}