.user-profile-actions {
  padding: 40px 0px 40px 20x;
  .profile-avatar {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fafafa;
  }
}

.onhover-div:hover .show-div {
  margin-right: -20px !important;
}
