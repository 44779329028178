.active_size {
  color: white !important;
  background-color: #ff4c3b;
  border-radius: 100%;
}

.loader-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-slider-component {
  .slick-slide {
    width: 100px !important;
    display: flex;
  }
}

.address-card-component {
  background-color: #fafafa;
  border: 1px solid #ddd;
  padding: 15px;
  letter-spacing: 0.3px;
  width: 100%;
  word-wrap: break-word;

  .card-header-section {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #e6e8eb;
    padding-bottom: 15px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 16px;
    //padding-bottom: 13px;
    letter-spacing: -0.65px;
    margin-top: 0;
    margin-bottom: none;
    word-wrap: break-word;
    width: 90%;
  }

  .addrress {
    padding-top: 10px;
    padding-right: 10px;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    line-height: 21px;
    margin-top: 0;
  }
}

.small-icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5px;
}

.stylist-section {
  .image-section {
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(66, 66, 66);

    .image {
      height: 150px;
      width: 150px;
      border-radius: 100%;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 20px;
      padding: 20px;
    }

    .person-name {
      font-size: large;
      font-weight: 700;
      color: white;
    }

    .designation {
      color: wheat;
    }

    .description {
      text-align: start;
    }
  }
}

@media (max-width: 577px) {
  .setting-sidebar {
    min-width: 100px !important;
    width: auto;
    min-height: 35px !important;
  }
}

.setting-box {
  width: 250px !important;
}

.setting-sidebar {
  min-width: 65px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}

.setting-sidebar.open-icon {
  right: 250px !important;
}

.social-icons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .icon-container {
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    color: #3c3c3c;
    padding: 6px;
    margin-top: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .whtaps-app {
    background-color: #4fce5d !important;
  }

  .email {
    background-color: #ff4c3b;
  }

  .call {
    background-color: #435a64;
  }

  .color-white {
    color: white !important;
  }
}

.social-icons-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;

  img {
    margin: 20px;
  }
}

.order-component {
  border-bottom: 1px solid gainsboro;

  .order-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .order-item-card {
    width: 100%;
    display: flex;
    align-items: center;

    .img-section {
      width: 100px;
    }
  }

  span {
    display: block;
    color: #2d3436;
  }
}

.tab-product .nav-material.nav-tabs {
  display: flex !important;
}

.deliverey-section {
  padding: 10px;
}

.table th {
  padding: 0.5rem !important;
}

.mobile-icon-footer {
  padding: 10px;

  .footer-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    label {
      color: white !important;
      font-size: 12px;
    }
  }
}

.top-service-banner-component {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #efefef;
  color: #636e72;

  .icon-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    p{
     margin-bottom: 0px;
    }
    @media (max-width: 577px) {
      flex-direction: column;
      h5{
        text-align: center;
        font-size: 12px;
      }
      p{
      
      }
    }

  
  }

  .lbl-color {
    color: #5f7d95;
  }
}

.categories-card {
  padding: 10px;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  margin: 0 auto 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.stylis-icon-section {
  position: fixed;
  top: 45%;
  right: 0;
  min-width: 65px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}

.service-icon {
  border: 1px solid black;
  padding: 5px;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
}
